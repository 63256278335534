import { RecordVoiceOver } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { getISOWeek } from 'date-fns';
import { get } from 'lodash';
import {
  checkTimes,
  extractDate,
  getLocalizedDay,
  localizedTimestampToUTC,
  utcTimestampToJSDate,
} from '../../../../models/dates';
import { isNewId, isTempId } from '../../../../models/records';
import { getSeasonByLeagueIdAndDate } from '../../../api/leagues';
import { ColumnSetting, ColumnType } from '../../../components/InfiniteTable';
import { BaseItem, DataType } from '../../../reducers/dataSlice';
import { columns } from './style';

import Roles from '../../../../models/Roles';
import { copyEvent } from '../../../../models/events';
import { authService } from '../../../../services/AuthService';
import { staticService } from '../../../../services/StaticService';
import { AdminIcons } from '../../../components/InfiniteTable/cells/AdminCell';
import { getSettingsByType } from '../../../components/Settings/table/ColumnPreference';
import { SettingsIcon } from '../../../components/Settings/table/SettingsIcon';
import { useAppSelector } from '../../../reducers/hooks';
import { RenderAudioColumn, RenderRightColumn } from './CustomRows';

export const useEventColumnSettings = (type: DataType) => {
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.EVENT_EDITOR]);
  const { tableSettings }: any = useAppSelector(state => state.settings);
  const currentSettings = getSettingsByType({
    table: type,
    userSettings: tableSettings,
  });
  let countryObj = {};
  const countries = staticService.data.countries.all;
  countries.forEach((country: any) => {
    countryObj = {
      ...countryObj,
      [country.code]: {
        key: country.code,
        header: country.code.toUpperCase(),
        datatype: ColumnType.Custom,
        style: columns.rights,
        editable: true,
        render: (x: any, y: any) => RenderRightColumn({ column: x, item: y }),
      },
    };
  });

  const allColumns: any = {
    id: {
      key: '_id',
      header: 'ID',
      datatype: ColumnType.Text,
      style: columns.id,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        if (isNewId(item._id)) {
          return '';
        }
        return item._id;
      },
    },
    week: {
      key: 'start_timestamp',
      header: 'Wk',
      datatype: ColumnType.Text,
      style: columns.week,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        const localDate = utcTimestampToJSDate(item.start_timestamp);
        return getISOWeek(localDate);
      },
    },
    day: {
      key: 'start_timestamp',
      header: '',
      datatype: ColumnType.Text,
      style: columns.day,
      editable: false,
      render(column: ColumnSetting, item: BaseItem) {
        return getLocalizedDay(new Date(item.start_timestamp));
      },
    },
    date: {
      key: 'start_timestamp',
      header: 'Date',
      datatype: ColumnType.Date,
      editable: true,
      style: columns.date,
      onBeforeChange(newTimestamp: any, item: BaseItem) {
        const utcNewTimestamp = localizedTimestampToUTC(newTimestamp);
        if (utcNewTimestamp === item.start_timestamp) return;
        return {
          start_timestamp: utcNewTimestamp,
          start_time: utcNewTimestamp,
        };
      },
    },
    start: {
      key: 'start_time',
      header: 'Start',
      datatype: ColumnType.Time,
      editable: true,
      style: columns.start,
      onBeforeChange(newTimestamp: any, item: BaseItem) {
        const utcNewTimestamp = localizedTimestampToUTC(newTimestamp);
        if (utcNewTimestamp === item.start_time) return;
        const { start_date, start_time } = checkTimes(utcNewTimestamp);
        return {
          start_time,
          start_timestamp: start_date,
        };
      },
    },
    sport: {
      key: 'sport',
      header: 'Sport',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        return staticService.getSports();
      },
      onGetDisplayValue: (options: any, value: any) => {
        return options[value];
      },
      onBeforeChange: (sportUpdated: any, item: BaseItem) => {
        return {
          sport: sportUpdated,
          league_id: 0,
        };
      },
      checkEdit: (item: BaseItem) => !!item?.source,
      editable: true,
      style: columns.sport,
    },
    league: {
      key: 'league_id',
      header: 'League',
      datatype: ColumnType.Dropdown,
      onGettingOptions: (item: BaseItem) => {
        const sport = get(item, 'sport', '');
        return sport ? staticService.getLeaguesBySports(sport) : [];
      },
      onGetDisplayValue: (options: any, value: any) => {
        return Object.values(options).find(
          (option: any) => option.value === value,
        );
      },
      onBeforeChange: async (
        leagueUpdated: any,
        item: BaseItem,
      ): Promise<any> => {
        const lid = Number(leagueUpdated);
        const date = extractDate(item.start_timestamp) || item.start_timestamp;
        const seasons = await getSeasonByLeagueIdAndDate(lid, date);
        const activeRights: any = [];
        if (seasons.length > 0) {
          const rights =
            staticService.getByIdOptions(leagueUpdated)?.rights || [];
          if (rights.length > 0) {
            rights.forEach((right: any) => {
              const leagueRightStartDate = new Date(right.rightStart);
              const leagueRightEndDate = new Date(right.rightEnd);
              const inputDate = new Date(date);
              if (
                inputDate >= leagueRightStartDate &&
                inputDate <= leagueRightEndDate
              ) {
                activeRights.push(right);
              }
            });
          }
        }

        return {
          league_id: seasons.length > 0 ? lid : 0,
          season_id: seasons.length > 0 ? seasons[0].id : 0,
          rights: activeRights,
        };
      },
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.league,
      textAlign: 'left',
    },
    round: {
      key: 'week',
      header: 'Rd',
      datatype: ColumnType.Text,
      editable: true,
      checkEdit: (item: BaseItem) => !!item?.source,
      style: columns.round,
      onBeforeChange(newRound: any, item: BaseItem) {
        return {
          week: newRound,
        };
      },
    },
    title: {
      key: 'event',
      header: 'Event',
      datatype: ColumnType.TextStatus,
      editable: true,
      style: columns.event,
    },
    stage: {
      key: 'stage',
      header: 'Stage',
      datatype: ColumnType.Text,
      editable: true,
      style: columns.stage,
      onBeforeChange(newStage: string, item: BaseItem) {
        return {
          stage: newStage,
        };
      },
    },
    audio: {
      key: 'has_eng_audio',
      header: (
        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          justifyContent="center"
        >
          <img
            loading="lazy"
            width="20"
            height="10"
            src="https://flagcdn.com/w20/gb.png"
            srcSet="https://flagcdn.com/w40/gb.png 2x"
            alt=""
          />
          <RecordVoiceOver />
        </Stack>
      ),
      datatype: ColumnType.Custom,
      style: columns.audio,
      editable: true,
      render: (x: any, y: any) => RenderAudioColumn({ column: x, item: y }),
    },
    ...countryObj,
    admin: {
      key: 'admin',
      header: <SettingsIcon dataType={type} />,
      datatype: ColumnType.Admin,
      style: columns.admin,
      editable: true,
      onGetIconToDisplay(item: BaseItem, changes: any, type: string) {
        const isNew = isTempId(item._id);
        const hasChanges = !!changes[`${type}:${item._id}`];
        return {
          history: !isNew,
          copy: !isNew && !item.isDeleted && !readOnly && !item?.source,
          associations: false,
          delete: !isNew && !item.isDeleted && !readOnly && !item?.source,
          undo: isNew || hasChanges,
        } as AdminIcons;
      },
      onCopy: (item: BaseItem, dispatch: any) => copyEvent(item, dispatch),
    },
  };

  const settings: any = [];

  currentSettings.forEach((column: any) => {
    if (!column.hidden) {
      settings.push(allColumns[column.id]);
    }
  });

  return settings;
};
