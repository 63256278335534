import { Check, NotInterested, QuestionMark, Star } from '@mui/icons-material';
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import clsx from 'clsx';
import { get } from 'lodash';
import { useContext } from 'react';
import Roles from '../../../../models/Roles';
import { getIcon, gettype } from '../../../../models/rights';
import { authService } from '../../../../services/AuthService';
import { EditCell } from '../../../components/EditCell';
import { CellProps } from '../../../components/InfiniteTable';
import { TableContext } from '../../../components/InfiniteTable/InfiniteTable';
import { ValueCell } from '../../../components/InfiniteTable/cells';
import { BaseItem } from '../../../reducers/dataSlice';
import { rightsStyle } from './style';
import { useAppDispatch } from '../../../reducers/hooks';
import { EditRights } from './EditRights';
import { getKeyFromId } from '../../../../models/records';
import { trapEvent } from '../../../components/hooks';

export function RenderAudioColumn<T extends BaseItem>({
  column,
  item,
}: CellProps<T>) {
  const value = get(item, column.key, '-');
  const dispatch = useAppDispatch();
  const { changes, refreshed, update } = useContext(TableContext);
  const key = getKeyFromId(item.type, item._id);
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.EVENT_EDITOR]);
  const refreshedColumns = (refreshed && refreshed[key]) || [];
  const refreshedAudio = refreshedColumns.has_eng_audio || {};
  const getLabel = () => {
    if (value === 'YES') {
      return 'Yes';
    }

    if (value === 'NO') {
      return 'No';
    }
    if (value === 'UN') {
      return '?';
    }
  };
  const getValue = () => {
    if (value === 'YES') {
      return <Check color={item.isDeleted ? 'warning' : 'success'} />;
    }

    if (value === 'NO') {
      return <NotInterested htmlColor="#fbfcfc" />;
    }
    if (value === 'UN') {
      return <QuestionMark htmlColor="#E5E2E2" />;
    }
  };
  if (!column.editable || item.isDeleted || readOnly) {
    return (
      <ValueCell
        value={getValue()}
        style={column.style}
        refreshed={refreshedColumns}
        property={column.key}
      />
    );
  } else {
    return (
      <EditCell
        key={column.key}
        popout
        id={item._id}
        field={column.key}
        className={clsx(
          column.style,
          changes.has_eng_audio && '-changed',
          //  refreshedAudio && 'blinking',
        )}
        value={getValue()}
        initValue={() => item.has_eng_audio}
        displayValue={getValue()}
        renderEditor={(input: any, { onCommit }: any) => (
          <Stack
            sx={{
              width: '80px',
              padding: 3,
              flexDirection: 'column',
            }}
            onClick={trapEvent}
            onKeyDown={trapEvent}
          >
            {item.audios.map((audio: any) => {
              return (
                <>
                  <FormLabel>{audio.name}</FormLabel>
                  <RadioGroup
                    value={input}
                    onChange={(e: any) => {
                      if (e.target.value) {
                        onCommit(e.target.value);
                      }
                    }}
                  >
                    <FormControlLabel
                      key={304}
                      value="YES"
                      label="Yes"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      key={305}
                      value="NO"
                      label="No"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      key={306}
                      value="UN"
                      label="?"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </>
              );
            })}
          </Stack>
        )}
        onChange={input => {
          console.log(input);
          dispatch(
            update({
              type: 'event',
              _id: item._id,
              delta: { has_eng_audio: input || [] },
            }),
          );
        }}
      />
    );
  }
}

interface RightIconProps {
  country: any;
  deleted: any;
  disabled?: any;
}

export function RightsIcon({ country, deleted, disabled }: RightIconProps) {
  const icon = getIcon(country?.type);

  const className =
    (disabled && '-level-0') ||
    (deleted && '-deleted') ||
    (country?.level && `-level-${country.level}`) ||
    (country && '-level-0') ||
    '-level-none';

  return (
    <Tooltip
      title={
        country ? (
          <Stack direction="column">
            <span>Type: {gettype(country?.type)}</span>
            <span>Importance: {country?.level || ''}</span>
          </Stack>
        ) : (
          ''
        )
      }
    >
      <Box className={className} sx={rightsStyle}>
        {icon}
      </Box>
    </Tooltip>
  );
}

export function RenderRightColumn<T extends BaseItem>({
  column,
  item,
}: CellProps<T>) {
  const rights = item.rights;
  const country = rights?.find((right: any) => right.country === column.key);
  const { changes, update, refreshed } = useContext(TableContext);
  const dispatch = useAppDispatch();
  const readOnly = !authService.hasRole([Roles.ADMIN, Roles.EVENT_EDITOR]);
  const canEdit = column.editable;
  const key = getKeyFromId(item.type, item._id);
  const refreshedColumns = (refreshed && refreshed[key]) || [];
  const givenRight: any = refreshedColumns?.rights?.find(
    (right: any) => right.country === column.key,
  );
  const refreshedRight = givenRight || [];

  if (!canEdit || item.isDeleted || readOnly) {
    return (
      <ValueCell
        value={
          <RightsIcon
            country={country}
            deleted={item.isDeleted}
            disabled={item?.isDisabled}
          />
        }
        style={column.style}
        refreshed={refreshedRight}
        property={column.key}
      />
    );
  } else {
    return (
      <EditCell
        key={column.key}
        popout
        id={item._id}
        field={column.key}
        className={clsx(
          column.style,
          changes.rights && '-changed',
          Object.keys(refreshedRight).length > 0 && 'blinking',
        )}
        value={item.rights}
        initValue={() => item.rights}
        displayValue={<RightsIcon country={country} deleted={item.isDeleted} />}
        renderEditor={(input: any, { onCommit }: any) => (
          <EditRights
            eventData={input}
            country={column.key}
            onCommit={onCommit}
          />
        )}
        onChange={input => {
          dispatch(
            update({
              type: 'event',
              _id: item.id,
              delta: { rights: input || [] },
            }),
          );
        }}
      />
    );
  }
}
